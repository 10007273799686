import React from 'react'
import { motion } from 'framer-motion'

const AgendaTracksTab = ({ agendaTracks, setSelectedTrackId, selectedTrackId }) => {

  const isSelected = (trackData) => trackData.track_id === selectedTrackId ? true : false

  const isSelectedFunc = (trackData, type) => {
    if (type === "bg") {
      if (trackData.track_id === selectedTrackId) return "black"
      else return "white"
    }
    if (type === "color") {
      if (trackData.track_id === selectedTrackId) return "white"
      else return "black"
    }
  }

  return (
    <>
      <div className='d-flex overflow-auto py-2 mb-3'>
        {agendaTracks.map((trackData, i) => (
          <motion.button
            className='date-btn text-center p-2 me-2 w-100'
            style={{ minWidth: "220px", background: isSelectedFunc(trackData, "bg"), color: isSelectedFunc(trackData, "color"), border: "1px solid #CACACA", borderRadius: "6px" }}
            whileHover={{ scale: !isSelected(trackData) ? 1.03 : 1 }}
            onClick={() => setSelectedTrackId(trackData.track_id)}
            key={`track-${trackData.track_id}`}
          >
            <p className='track-title m-0'>{trackData.track_title}</p>
            <p className='track-description ms-1 m-0'>{trackData.track_description}</p>
            <style jsx>{`
                        .track-description {
                            font-weight: 300;
                            font-size: 0.8125rem;
                        }
                        .track-title {
                            font-size: 1rem;
                            font-weight: 600;
                        }
                        .date-btn {
                            width: 70px;
                            border: 1px solid #CACACA;
                            border-radius: 6px;
                        }
                    `}</style>
          </motion.button >
        ))}
      </div >
    </>
  )
}

export default AgendaTracksTab
