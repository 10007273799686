import UseCasesSec1 from "../../../public/img/useCases/Conference/AWSCDSingapore2.jpg";
import UseCasesSec2 from "../../../public/img/useCases/Conference/AWSCDSriLanka2.jpg";
import UseCasesSec3 from "../../../public/img/useCases/Conference/AWSCDVirtualEdition2.jpg";
import UseCasesSec4 from "../../../public/img/useCases/Conference/AWSPune.jpg";
import UseCasesSec5 from "../../../public/img/useCases/Conference/DataOpsObservabilityConf2.jpg";
import UseCasesSec6 from "../../../public/img/useCases/Conference/DevfestAurangabad2.jpg";
import UseCasesSec7 from "../../../public/img/useCases/Conference/DevNation2.jpg";
import UseCasesSec8 from "../../../public/img/useCases/Conference/DevOpsDaysIndia2.jpg";
import UseCasesSec9 from "../../../public/img/useCases/Conference/ExpertsLive2.jpg";
import UseCasesSec10 from "../../../public/img/useCases/Conference/GDGPune2023.jpg";
import UseCasesSec11 from "../../../public/img/useCases/Conference/JECRCCLOUDSUMMIT2.jpg";
import UseCasesSec12 from "../../../public/img/useCases/Conference/KCDMumbai.jpg";
import UseCasesSec13 from "../../../public/img/useCases/Conference/PyConfHyderabad2.jpg";
import UseCasesSec14 from "../../../public/img/useCases/MeetupsAndWebinars/AzugaLogo2.jpg";
import UseCasesSec15 from "../../../public/img/useCases/MeetupsAndWebinars/DM2.jpg";
import UseCasesSec16 from "../../../public/img/useCases/MeetupsAndWebinars/EduHub.jpg";
import UseCasesSec17 from "../../../public/img/useCases/MeetupsAndWebinars/Flutternashik.jpg";
import UseCasesSec18 from "../../../public/img/useCases/MeetupsAndWebinars/GDGTrichy.jpg";
import UseCasesSec19 from "../../../public/img/useCases/MeetupsAndWebinars/ReCap.jpg";
import UseCasesSec20 from "../../../public/img/useCases/MeetupsAndWebinars/SodaFoundation.jpg";
import UseCasesSec21 from "../../../public/img/useCases/MeetupsAndWebinars/StudyJam.jpg";
import UseCasesSec22 from "../../../public/img/useCases/MeetupsAndWebinars/WomenInData.jpg";
import UseCasesSec23 from "../../../public/img/useCases/MeetupsAndWebinars/WOW.jpg";
import UseCasesSec24 from "../../../public/img/useCases/TechFestsHackathons/GDSCWOWPUNE.jpg";
import UseCasesSec25 from "../../../public/img/useCases/TechFestsHackathons/Prakarsh2.jpg";
import UseCasesSec26 from "../../../public/img/useCases/TechFestsHackathons/Samhita.jpg";
import UseCasesSec27 from "../../../public/img/useCases/TechFestsHackathons/Tharang2.jpg";
import UseCasesSec28 from "../../../public/img/useCases/WorkshopsTrainings/AWS.jpg";
import UseCasesSec29 from "../../../public/img/useCases/WorkshopsTrainings/EthnusTech.jpg";
import UseCasesSec30 from "../../../public/img/useCases/WorkshopsTrainings/IWD2023.jpg";
import UseCasesSec31 from "../../../public/img/useCases/WorkshopsTrainings/JavaScriptMeetup.jpg";
import UseCasesSec32 from "../../../public/img/useCases/WorkshopsTrainings/KotlinConfKUGKolkata1.jpg";
import UseCasesSec33 from "../../../public/img/useCases/WorkshopsTrainings/UpesacmTech.jpg";

// Images based on sections available on the use cases page
export const Section1 = [
  {
    name: "AWS CD Singapore",
    image: UseCasesSec1,
  },
  {
    name: "AWS CD SriLanka",
    image: UseCasesSec2,
  },
  {
    name: "AWS CD Virtual Edition",
    image: UseCasesSec3,
  },
  {
    name: "AWS Pune",
    image: UseCasesSec4,
  },
  {
    name: "DataOps Observability Conf2",
    image: UseCasesSec5,
  },
  {
    name: "Devfest Aurangabad",
    image: UseCasesSec6,
  },
  {
    name: "Dev Nation",
    image: UseCasesSec7,
  },
  {
    name: "Dev Ops DaysIndia",
    image: UseCasesSec8,
  },
  {
    name: "Experts Live",
    image: UseCasesSec9,
  },
  {
    name: "GDG Pune 2023",
    image: UseCasesSec10,
  },
  {
    name: "JECRC CLOUD SUMMIT 2",
    image: UseCasesSec11,
  },
  {
    name: "KCD Mumbai",
    image: UseCasesSec12,
  },
  {
    name: "PyConf Hyderabad",
    image: UseCasesSec13,
  },
];

export const Section2 = [
  {
    name: "GDSC WOW PUNE",
    image: UseCasesSec24,
  },
  {
    name: "Prakarsh 2",
    image: UseCasesSec25,
  },
  {
    name: "Samhita",
    image: UseCasesSec26,
  },
  {
    name: "Tharang",
    image: UseCasesSec27,
  },
  {
    name: "Flutter Nashik",
    image: UseCasesSec14,
  },
  {
    name: "DM",
    image: UseCasesSec15,
  },
  {
    name: "Kotlin Kolkata 2k23",
    image: UseCasesSec8,
  },
];

export const Section3 = [
  {
    name: "AWS",
    image: UseCasesSec28,
  },
  {
    name: "Ethnus Tech",
    image: UseCasesSec29,
  },
  {
    name: "IWD 2023",
    image: UseCasesSec30,
  },
  {
    name: "JavaScript Meetup",
    image: UseCasesSec31,
  },
  {
    name: "Kotlin Conf KUG Kolkata",
    image: UseCasesSec32,
  },
  {
    name: "Upesacm Tech",
    image: UseCasesSec33,
  },
];

export const Section4 = [
  {
    name: "Azuga",
    image: UseCasesSec14,
  },
  {
    name: "Devnation Site Day",
    image: UseCasesSec15,
  },
  {
    name: "EduHub",
    image: UseCasesSec16,
  },
  {
    name: "Flutter Nashik",
    image: UseCasesSec17,
  },
  {
    name: "GDG Trichy",
    image: UseCasesSec18,
  },
  {
    name: "AWS ReCap",
    image: UseCasesSec19,
  },
  {
    name: "Soda Foundation",
    image: UseCasesSec20,
  },
  {
    name: "Study Jam",
    image: UseCasesSec21,
  },
  {
    name: "Women In Data",
    image: UseCasesSec22,
  },
  {
    name: "GDG WOW",
    image: UseCasesSec23,
  },
];



// Status Constants
export const SOLD_OUT = "Sold Out";
export const EXPIRED = "Expired";
export const REGISTER = "Register";
export const BUY_NOW = "Buy Now";